import React from "react"

import Header from "./components/Header"
import IntroSection from "./sections/Intro"
import AboutSection from "./sections/About"
import MissionSection from "./sections/Mission"
import MembersSection from "./sections/Members"
// import ResourcesSection from "./sections/Resources"
import Footer from "./components/Footer"
import SideNav from "./components/SideNav"
// import ScrollHandler from "./components/ScrollHandler"
import QueueHandler from "./components/QueueHandler"

function App() {

	// const dispatch = useDispatch()
	// useEffect(() => {
	// 	dispatch({ type: 'SET_SECTION', section: 'intro' })
	// }, [])

	return (
		<div>
			{/*<ScrollHandler />*/}
			<QueueHandler />

			<Header />

			{/*<SideNav />*/}

			<main>
				<IntroSection />
				<AboutSection />
				<MissionSection />
				<MembersSection />
				{/*<ResourcesSection />*/}
				<Footer />
			</main>


		</div>
	)

}

export default App
