const sections = ['intro', 'about', 'mission', 'members', 'footer']

export function changeSection(section) {
	return {
		type: 'CHANGE_SECTION',
		section: section
	}
}

const SystemSchema = {
	section: null,
	subsection: null,
	direction: 'down',
	speed: 'normal',
	queue: [] // section transition queue
}


export default function SystemReducer(state = SystemSchema, action) {

	switch (action.type) {
		case 'SET_SECTION':
			return {
				...state,
				section: action.section,
				subsection: action.subsection
			}
		case 'SET_DIRECTION':
			return {
				...state,
				direction: action.direction
			}
		case 'QUEUE_SECTION':
			return {
				...state,
				queue: [...state.queue, { section: action.section, subsection: action.subsection } ]
			}
		case 'NEXT_SECTION':
			let next = state.queue.shift()
			let direction = 'down'
			if (sections.indexOf(state.section) >= sections.indexOf(next.section)) direction = 'up'
			console.log(next, state.queue)
			// let speed = state.queue.length > 1 ? 'fast' : 'normal'
			let speed = 'fast'
			return {
				...state,
				section: next.section,
				subsection: next.subsection,
				queue: state.queue,
				direction: direction,
				speed
			}
		case 'REMOVE_SECTION':
			let queue = [...state.queue]
			queue.shift()
			return {
				...state,
				queue: queue
			}
		default:
			return state
	}

}
