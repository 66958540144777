import React from 'react'
import PageSection from "../components/PageSection"

import s from './About.module.sass'

export default function AboutSection() {

	return (
		<PageSection name={"about"}>
			<div className={s.wrapper}>
				<div className={s.bg} />
				<div className={s.content}>
					<h2>Creating Synergies for the Acceleration and Advancement of Federated Learning</h2>
					Federated Learning is bound to change the way AI is used and implemented – FLC aims to accelerate technology best practices, vertical application knowhow, and cooperative ecosystem for federated learning.
				</div>
			</div>
		</PageSection>
	)

}
