import React, {useRef, useState, useEffect} from 'react'
import cx from 'classnames'
import PageSection from "./PageSection"

import s from './Footer.module.sass'


function Footer() {

	const [sent, setSent] = useState(false)

	const refs = {}

	function handleSubmit(e) {
		e.preventDefault()
		let name = refs.name.value
		let email = refs.email.value
		let message = refs.message.value
		console.log(name, email, message)

		fetch("https://formsubmit.co/ajax/jimmy.hu@chinapex.com", {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
				body: JSON.stringify({name: name, email: email, message: message})
			})
			.then(res => res.json())
			.then(res => {
				console.log(res)
				if (res.success === 'true') {
					setSent(true)
				}
			})
	}

	return (
		<PageSection name={"footer"}>

			<footer className={s.wrapper}>

				<div className={s.text}>
					<div>Federated Learning Consortium (FLC)<br />is a research organization registered in Hong Kong. Contact us at info@flc.ai or by submitting an inquiry below</div>
					{/*<a href={"mailto:info@flc.ai"}>info@flc.ai</a>*/}

					<form action="" method="POST" onSubmit={handleSubmit}>

						<div className={cx(s.message, { [s.active]: sent } )}>
							Inquiry received!
						</div>

						<input type={"text"}
					   		disabled={sent}
					   		ref={r => refs.name = r}
					   		placeholder={'name'} />
						<input type={"email"}
						   	disabled={sent}
					   		ref={r => refs.email = r}
					   		placeholder={'email'} />
						<textarea disabled={sent}
					  		ref={r => refs.message = r}
					  		placeholder={'message'} />
						<input disabled={sent} type={"submit"} name={"submit"} />
					</form>

					{/*<form action="https://formsubmit.co/jimmy.hu@chinapex.com" method="POST">*/}
					{/*	<input type={"text"} name={"name"} />*/}
					{/*	<input type={"email"} name={"email"} />*/}
					{/*	<textarea />*/}
					{/*	<input type={"submit"} name={"submit"} />*/}
					{/*</form>*/}
				</div>

				{/*<div className={s.social}>*/}
				{/*	<a>.</a>*/}
				{/*	<a>.</a>*/}
				{/*	<a>.</a>*/}
				{/*	<a>.</a>*/}
				{/*	<a>.</a>*/}
				{/*</div>*/}

			</footer>

		</PageSection>
	)

}

export default Footer
