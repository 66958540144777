import React from 'react'
import {CSSTransition} from "react-transition-group"
import {useSelector} from "react-redux"
import Tracker from "./Tracker"
// import cx from "classnames"

export default function PageSection(props) {

	const {section, direction, speed} = useSelector(state => state)

	let mount = props.mount !== false

	return (
		<>
			<section className={"fixedHeight"}>
				<Tracker name={props.name} />
			</section>
			{/*<div className={cx("page", { active: section === props.name })}>*/}
			<CSSTransition in={section === props.name} timeout={speed === 'fast' ? 500 : 2000} className={`page ${direction} ${speed}`}
		   		mountOnEnter={mount} unmountOnExit={mount}>
				<div>{props.children}</div>
			</CSSTransition>
		</>
	)

}
