import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from "react-redux"
import cx from 'classnames'

import s from './Header.module.sass'

function Header() {

	let sections = [
		{
			name: 'intro',
			label: 'Introduction',
			ref: useRef()
		},
		{
			name: 'about',
			label: 'About',
			ref: useRef()
		},
		{
			name: 'mission',
			label: 'Mission',
			ref: useRef()
		},
		{
			name: 'members',
			label: 'Members',
			ref: useRef()
		},
		{
			name: 'footer',
			label: 'Contact',
			ref: useRef()
		}
	]
	const {section, subsection, direction, queue} = useSelector(state => state)
	const [button, setButton] = useState({ left: '250px', width: '100px' })
	const [expanded, setExpanded] = useState(false)

	useEffect(() => {
		console.log('section to switch to', section)
		if (!section) return
		let item = sections.find(obj => obj.name === section).ref.current
		let rect = item.getBoundingClientRect()
		console.log(item.getBoundingClientRect())
		setButton({ left: rect.x, width: rect.width - 5 })
	}, [section])

	let theme = ['mission'].indexOf(section) > -1 ? 'light' : 'dark'

	function handleClick(name) {
		let tracker = document.querySelector('.tracker[name="' + name + '"]')
		let scrollTarget = tracker.getBoundingClientRect().top + window.scrollY
		window.scrollTo(0, scrollTarget, { behavior: 'smooth' })
		setExpanded(false)
	}

	let links = sections.map(item => (
		<div className={item.name === section ? s.active : undefined}
	 		onClick={() => handleClick(item.name)}
			key={item.label} ref={item.ref}>{item.label}</div>
	))

	function toggleExpanded() {
		setExpanded(!expanded)
	}

	return (
		<div className={s.wrapper}>

			{/*<div style={{ position: 'fixed', zIndex: 1000, top: '0px', right: '0px', padding: '10px', background: 'black', color: 'white', fontSize: '12px' }}>*/}
			{/*	queue: {queue.toString()}<br />*/}
			{/*	section: {section}<br />*/}
			{/*	subsection: {subsection || 'n/a'}<br />*/}
			{/*	direction: {direction}*/}
			{/*</div>*/}

			<header>

				<div className={s.logo}>
					<img src={`logo-${theme === 'light' ? 'dark' : 'light'}.png`} alt={"federated learning consortium logo"} />
				</div>

				<div className={cx(s.button, { [s.dark]: theme === 'light' } )} style={{ left: button.left, width: button.width + 'px' }} />

				<div className={cx(s.menuBtn, { [s.light]: theme === 'light', [s.expanded]:  expanded })}
			 		onClick={toggleExpanded}>
					<span  /><span /><span  />
				</div>

				<nav className={cx(theme, { [s.expanded]: expanded } )}>
					{links}
				</nav>

			</header>

		</div>
	)

}

export default Header
