import React, {useEffect, useState} from 'react'
import s from "./Countdown.module.sass"

const launchTime = new Date('11/1/2021').getTime()
let currentTime = new Date().getTime()
// console.log(launchTime)

export default function CountDown() {

	const [{days, hours, minutes, seconds}, setRemaining] = useState({})

	useEffect(() => {
		setInterval(countdown, 1000)
	}, [])

	function addLeading(num) {
		return ("00" + num).slice(-2)
	}

	function countdown() {
		currentTime += 1000
		// console.log(launchTime, currentTime)

		let rTime = launchTime - currentTime
		// console.log(rTime)

		let day = 24 * 60 * 60 * 1000
		let hour = 60 * 60 * 1000
		let minute = 60 * 1000
		let second = 1000
		let days = Math.floor(rTime / day)
		rTime = rTime % day
		let hours = Math.floor(rTime / hour)
		rTime = rTime % hour
		let minutes = Math.floor( rTime / minute)
		rTime = rTime % minute
		let seconds = Math.floor(rTime / second)
		setRemaining({
			days: addLeading(days),
			hours: addLeading(hours),
			minutes: addLeading(minutes),
			seconds: addLeading(seconds)
		})
	}

	return (

		<div className={s.countdown}>
			<div className={s.text}>FLC Official Launch In:</div>
			<div className={s.tiles}>
				<div>
					{days}
					<label>Days</label>
				</div>
				<div>
					{hours}
					<label>Hours</label>
				</div>
				<div>
					{minutes}
					<label>Minutes</label>
				</div>
				<div>
					{seconds}
					<label>Seconds</label>
				</div>
			</div>
		</div>
	)

}
