import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"

import PageSection from "../components/PageSection"

import technologyImg from '../media/members/internet-technology-fiber-optic-background-P6VM82H.jpg'
import systemImg from '../media/members/blade-server-in-san-at-datacenter-F6AWKD3.jpg'
import enterprisesImg from '../media/members/big-business-office-with-loft-rooms-for-conference-M5RC5H8.jpg'
import academicsImg from '../media/members/startup-working-online-technology-research-concept-P4VMTKS.jpg'
import relatedImg from '../media/members/pexels-kindel-media-8566629.jpg'

import s from './Members.module.sass'
import {CSSTransition} from "react-transition-group"


const data = [
	{
		name: 'technology',
		label: 'Technology Providers',
		description: 'Solution providers that enable federated learning capabilities as a part of a vertical-focused or use case-based technology solution. These capabilities may be ready-to-use, SaaS-based, or require implementation.',
		cover: technologyImg
	},
	{
		name: 'system',
		label: 'System Integrators',
		description: 'Implementation service providers that work with technology vendors to best serve enterprises’ needs and offer federated learning-capabilities as a part of a larger solution (including services components).',
		cover: systemImg
	},
	{
		name: 'enterprises',
		label: 'Enterprises',
		description: 'Enterprises looking to leverage federated learning to tackle particular business or technical problems. Can be in planning or pilot stage, or already running FL in production.',
		cover: enterprisesImg
	},
	{
		name: 'academics',
		label: 'Academics & Experts',
		description: 'Individual membership for academics, researchers, and experts who are involved in federated learning or related technologies’ endeavors. Experts can include both pure academics as well as corporate research roles.',
		cover: academicsImg
	},
	{
		name: 'related',
		label: 'Related Technologies',
		description: 'Technology companies in related technologies such as MPC, AI, blockchain, and edge computing who have an interest or intent in using or combining existing solutions with federated learning.',
		cover: relatedImg
	}
]

function MembersSection() {

	const dispatch = useDispatch()
	const {section, subsection, direction, speed} = useSelector(state => state)

	let nav = data.map(item => (
		<div className={subsection === item.name ? s.active : ''}
			onClick={() => dispatch({ type: 'SET_SECTION', section: 'members', subsection: item.name })}>
			{item.label}
		</div>
	))

	let pageSections = data.map(item => <PageSection name={`members-${item.name}`} />)

	let content = data.map(item => (
		<CSSTransition key={item.name} in={item.name === subsection} timeout={500} mountOnEnter unmountOnExit>
			<div className={s.info}>
				<div className={s.cover}>
					<img src={item.cover} />
				</div>
				<div className={s.textWrapper}>
					<div className={s.text}>
						<h3>{item.label}</h3>
						{item.description}
					</div>
				</div>
			</div>
		</CSSTransition>
	))

	return (
		<div>

			<CSSTransition in={section === 'members'} timeout={speed === 'fast' ? 500 : 2000}
		   		className={`page ${direction} ${speed} ${s.wrapper}`} mountOnEnter unmountOnExit>

				<div className={s.wrapper}>

					<div className={s.main}>

						<div className={s.inner}>

							<h2>Members</h2>

							<div>
								Federated Learning Consortium does not charge membership fees, but has a criteria framework for members across five categories.
							</div>

							<div className={s.menu}>
								{nav}
							</div>

						</div>

					</div>

					<div className={s.infoWrapper}>
						{content}
					</div>

				</div>
			</CSSTransition>

			{pageSections}

		</div>
	)

}

export default MembersSection
