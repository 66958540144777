import React, {useState, useEffect, useRef} from 'react'
import {motion} from 'framer-motion'
import cx from 'classnames'
import PageSection from "../components/PageSection"

import introVideo from '../media/intro-v4.mp4'
import englishSubtitles from '../library/en.srt'
import playIcon from '../images/icon-play.png'
import pauseIcon from '../images/icon-pause.png'
import poster  from '../images/7951630039213_.jpg'

import s from './Intro.module.sass'
import {CSSTransition, TransitionGroup} from "react-transition-group"
import CountDown from "../components/Countdown";

let data = { en: [], zh: [] }
fetch(englishSubtitles)
	.then(res => res.text())
	.then(text => {
		// console.log(text)
		// let split = text.split('\r')
		let split = text.split(/[\r\n]+/g)
		// console.log(split)
		split = split.filter(obj => {
			if (!(obj < 200)) return obj
		})
		// console.log(split)

		let current = { text: '' }
		split.forEach(item => {
			if (item.indexOf('00:') === 0) { // this is a time string
				if (current.start >= 0) {
					data.en.push(current)
					current = { text: '' }
				}
				let [start, end] = item.split(' --> ')
				current.start = getTimeInSeconds(start)
				current.end = getTimeInSeconds(end)
			} else { // This is the text, can assume it always get pushed after
				current.text += ' ' + item
			}
		})

		console.log(data)
	})

function getTimeInSeconds(timeString) {
	let time = timeString.split(':')
	let seconds = parseInt(time[1], 10) * 60 + parseInt(time[2], 10)
	console.log(time, seconds)
	return seconds
}

let subTracker
let currentTime

function IntroSection() {

	const [started, setStarted] = useState(false)
	const [playing, setPlaying] = useState(false)
	const [sub, setSub] = useState({})
	const vidRef = useRef()
	const controlRef = useRef()
	const markerRef = useRef()

	function togglePlay() {
		console.log('play status', playing)
		if (playing) {
			vidRef.current.pause()
			clearInterval(subTracker)
		} else {
			if (!started) setStarted(true)
			// vidRef.current.currentTime = 240
			vidRef.current.play()
			subTracker = setInterval(setSubtitle, 1000)
		}
		setPlaying(!playing)
	}

	function setSubtitle() {
		if (currentTime === vidRef.current.currentTime) {
			setPlaying(false)
			clearInterval(subTracker)
		}
		currentTime = vidRef.current.currentTime
		let found = data['en'].filter(obj => {
			if (obj.start <= currentTime && obj.end > currentTime) return obj
		})
		if (found.length < 1) {
			setSub({})
			return
		}
		console.log(found)
		if (found[0] !== sub) setSub(found[0])
	}

	// function togglePlay() {
	// 	let marker = markerRef.current
	// 	// marker.style.left = '50%'
	// }

	// function handleDrag(e, info) {
	// 	console.log(e)
	// }

	return (
		<PageSection name={"intro"} mount={false}>

			<div className={s.wrapper}>
				<div className={s.introVideo} onClick={togglePlay}>
					<video autoPlay={playing} playsInline={true} ref={vidRef} controls={false}>
						<source src={introVideo} />
					</video>

					<div className={cx(s.shade, { [s.active]: !playing })}>
						<img src={poster} />
					</div>

					<div className={cx(s.toggle, { [s.active]: playing })}>
						<img src={pauseIcon} />
						<img src={playIcon} />
						{!started && (
							<div className={s.text}>
								Discover Federated Learning - Play Intro
							</div>
						)
						}
					</div>

					<div className={cx(s.subtitles, { [s.active]: playing })}>
						<TransitionGroup>
							<CSSTransition key={sub.start} className={s.text} timeout={1000}>
								<div>{sub.text}</div>
							</CSSTransition>
						</TransitionGroup>
					</div>
				</div>

				{/*<div className={s.controls}>*/}
				{/*	<div className={s.button} onClick={togglePlay}>*/}
				{/*		>*/}
				{/*	</div>*/}
				{/*	<div className={s.bar} ref={controlRef}>*/}
				{/*		/!*Create another overlaying draggable item and have the underneath thing just react to state?*!/*/}
				{/*		<motion.div className={s.dot} drag={"x"} dragConstraints={controlRef}*/}
				{/*			dragElastic={0}*/}
				{/*			dragMomentum={false}*/}
				{/*			onDrag={handleDrag}*/}
				{/*			ref={markerRef}*/}
				{/*			/>*/}
				{/*	</div>*/}
				{/*</div>*/}

				{/*<CountDown />*/}

			</div>

		</PageSection>
	)

}

export default IntroSection
