import React from 'react'

import PageSection from "../components/PageSection"

import s from './Mission.module.sass'

const data = [
	<>Explore <strong>technical implementation best practices</strong> of federated learning technology, optimizing learning effectiveness, performance, security, and process management.</>,
	<>Further the understanding of federated learning use cases across a multitude of industries via <strong>experimental pilots and completed case studies.</strong></>,
	<><strong>Discover and create synergies</strong> across the federated learning ecosystem: technology providers, system integrators, enterprises, and academic researchers.</>,
	<><strong>Contribute to the growth and adoption of federated learning</strong> globally, along with related privacy-preserving technologies such as multi-party computation (MPC)</>
]

function MissionSection() {

	let points = data.map((point, no) => (
		<div className={s.point}>
			{/*<div className={s.bg} />*/}
			<div className={s.icon}>
				<div className={s.bg} />
				{/*<div className={s.number}>{no + 1}</div>*/}
			</div>
			{point}
		</div>
	))

	return (
		<PageSection name={"mission"}>

			<div className={s.wrapper}>

				<div className={s.content}>
					<h2>Mission</h2>
					<div className={s.points}>
						{points}
					</div>

				</div>
			</div>

		</PageSection>
	)

}

export default MissionSection
