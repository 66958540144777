import React, {useEffect} from 'react'
import {useInView} from "react-intersection-observer"
import {useDispatch} from "react-redux"

export default function Tracker(props) {

	const {ref, inView} = useInView()
	const dispatch = useDispatch()

	let [section, subsection] = props.name.split('-')

	useEffect(() => {
		if (inView) {
			// console.log('in view!', props.name, inView.toString())
			// dispatch({ type: 'SET_SECTION', section: split[0], subsection: split[1] })
			dispatch({ type: 'QUEUE_SECTION', section, subsection })
		}
	}, [inView])

	return (
		<div className={"tracker"} ref={ref} name={section} />
	)
}
