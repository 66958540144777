import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux"

// let transitioning = false
let firstQueue = false

function QueueHandler() {

	const {section, queue} = useSelector(state => state)
	const [transitioning, setTransitioning] = useState(false)
	const dispatch = useDispatch()

	// useEffect(() => {
	// 	console.log('queue is changing')
	// 	if (queue.length < 1 || transitioning) return
	//
	// 	transition()
	// }, [section])

	useEffect(() => {
		if (queue.length > 0 && !transitioning) {
			firstQueue = true
			transition()
		}
	}, [queue, transitioning])

	// function checkQueue() {
	// }

	function transition() {
		// console.log('should transition')
		setTransitioning(true)
		let target = queue[0]
		// dispatch({ type: 'SET_SECTION', section: 'intro' } )
		// dispatch({ type: 'REMOVE_SECTION' } )
		dispatch({ type: 'NEXT_SECTION' } )
		window.setTimeout(() => {
			setTransitioning(false)
			// transition()
		}, 500)
	}

	return null

}

export default QueueHandler
